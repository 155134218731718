export const validateService = {
    predictionValidate


}


function predictionValidate(predictionFields,setpredictionFieldsErr){
    console.log(predictionFields)
    let formValidated = true;
    if (predictionFields.question.trim() === "") {
        formValidated = false;
        setpredictionFieldsErr((obj) => {
             return {
             ...obj,
             question: "Please enter question",
             start_time: "",
             end_time: "",
             outcome: "",
             threshold_time:""
             
             };
         });
 }else  if (predictionFields.description.trim() === "") {
  formValidated = false;
  setpredictionFieldsErr((obj) => {
       return {
       ...obj,
       question: "",
       description:"Please enter description",
       start_time: "",
       end_time: "",
       outcome: "",
       threshold_time:""
       
       };
   });
}else  if (predictionFields.duration.trim() === "") {
  formValidated = false;
  setpredictionFieldsErr((obj) => {
       return {
       ...obj,
       question: "",
       description:"",
       duration:"Please select duration",
       start_time: "",
       end_time: "",
       outcome: "",
       threshold_time:""
       
       };
   });
}  else if (predictionFields.startDateTime.trim() ==""){
    
     formValidated = false;
     setpredictionFieldsErr((obj) => {
         return {
         ...obj,
         question: "",
       start_time: "Please select betting start time",
       end_time: "",
       outcome: "",
       threshold_time:""
         
         };
     });
 }
//  else if (predictionFields.endDateTime.trim() === "") {
//    formValidated = false;
//    setpredictionFieldsErr((obj) => {
//      return {
//        ...obj,
//        question: "",
//        start_time: "",
//        end_time: "Please select betting betting End time",
//        outcome: "",
//        threshold_time:""
//      };
//    });
//  }
//  else if (predictionFields.outcome.trim() === "") {
//     formValidated = false;
//     setpredictionFieldsErr((obj) => {
//       return {
//         ...obj,
//         question: "",
//         start_time: "",
//         end_time: "",
//         outcome: "Please select outcome",
//         threshold_time:""
//       };
//     });
  // }
  
  else if (predictionFields.threshold_time === "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time:"Please select threshold time"
      };
    });
  }else{
    formValidated = true;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time:""
      };
    });
  }
return formValidated
}