import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { InlineLoader } from "../../components/loader";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
import 'react-toastify/dist/ReactToastify.css';
import { apiService } from "../../service/api.service";
import { MyContext, formatBalance,fundWalletAddress } from "../../config/config";
import { useContext } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
export const Home = () => {
  const { filterType, setFilterType, openLoad, setOpenLoad } = useContext(MyContext)
  const [loader, setLoader] = useState(false);
  const [dashbordData, setDashbordData] = useState([]);
  const [fundWalletBal, setfundWalletBal] = useState(0);
  const [copied,setcopied] = useState(false)
  const setcopytext =()=>{
    setTimeout(() => {
      setcopied(false)
    }, "1000");
    
  }
  useEffect(() => {
    dashboardList()
    getFundBalance()
  }, [])
  const copytooltip = (
    <Tooltip id="tooltip">
    {copied?"Copied" :"Copy"}
    </Tooltip>
    
  );
  async function dashboardList() {
    setLoader(true)
    try {
      const response = await apiService.Dashboard()
      if (response?.status == 200) {
        console.log("?????????????dashbord", response?.data?.data)
        setDashbordData(response?.data)

        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  async function getFundBalance() {
    setLoader(true)
    try {
      const response = await apiService.getFundBalance()
      if (response?.status == 200) {
        console.log("?????????????dashbord", response?.data?.data)
        setfundWalletBal(formatBalance(response?.data?.data))

        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }
  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area main-home-top-area">
      


          <Row className="justify-content-center">

            <Col md={12} lg={11} className="p-0">
              <Row className="justify-content-center m-2">
                {loader == true ? <ThreeDotSpinner /> : ""}

                {/* <Col md={10} className="mb-3 text-center">
                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                </Col> */}
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/box-wallet.svg").default}
                      />
                    </div>
                    {/* <Link to="#" className="dashboardlink" onClick={()=>setOpenLoad(true)}> */}
                    <div className="card-content">
                      <h3>Fund Wallet</h3>
                      <p>{fundWalletAddress.substring(0,4)}...{fundWalletAddress.substring(fundWalletAddress.length-4)}<OverlayTrigger placement="top" overlay={copytooltip}><CopyToClipboard text={fundWalletAddress}  onCopy={() => setcopied(true)}><i class="fa fa-clipboard" aria-hidden="true" onClick={setcopytext} style={{cursor:"pointer"}}></i></CopyToClipboard></OverlayTrigger></p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ?
                          <InlineLoader />
                          :
                          <>
                            {fundWalletBal ? <>{fundWalletBal} <img className="usd-coin" src={require("../../assets/images/usd-coin.png")} alt="img" /></> : <>-</>}

                          </>
                        }
                      </h2>

                    </div>
                    {/* </Link> */}
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/total-user.svg").default}
                      />
                    </div>
                    <Link to="/users" className="dashboardlink" onClick={() => setFilterType("trucker")}>
                      <div className="card-content">
                        <h3>Total Users</h3>
                        <h2>
                          {" "}
                          {loader ?
                            <InlineLoader />
                            :
                            <>
                              {dashbordData ? <>{dashbordData?.userCount}</> : <>-</>}
                            </>
                          }
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col>


                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/prediction-count.svg").default}
                      />
                    </div>
                    <Link to="/predictions" className="dashboardlink" onClick={() => setOpenLoad(true)}>
                      <div className="card-content">
                        <h3>Total Predictions </h3>
                        <h2>
                          {" "}
                          {loader ?
                            <InlineLoader />
                            :
                            <>
                              {dashbordData ? <>{dashbordData?.predictionCount}</> : <>-</>}

                            </>
                          }
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/comission.svg").default}
                      />
                    </div>
                    {/* <Link to="#" className="dashboardlink" onClick={()=>setOpenLoad(true)}> */}
                    <div className="card-content">
                      <h3>Total commission</h3>
                      <h2>
                        {" "}
                        {loader ?
                          <InlineLoader />
                          :
                          <>
                            {dashbordData ? <>{formatBalance(dashbordData?.totalCommission)}</> : <>-</>}

                          </>
                        }
                      </h2>

                    </div>
                    {/* </Link> */}
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/amount.svg").default}
                      />
                    </div>
                    {/* <Link to="#" className="dashboardlink" onClick={()=>setOpenLoad(true)}> */}
                    <div className="card-content">
                      <h3>Total amount</h3>
                      <h2>
                        {" "}
                        {loader ?
                          <InlineLoader />
                          :
                          <>
                            {dashbordData ? <>{formatBalance(dashbordData?.totalAmount)}</> : <>-</>}

                          </>
                        }
                      </h2>

                    </div>
                    {/* </Link> */}
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/reward-amount.svg").default}
                      />
                    </div>
                    {/* <Link to="#" className="dashboardlink" onClick={()=>setOpenLoad(true)}> */}
                    <div className="card-content">
                      <h3>Total reward amount</h3>
                      <h2>
                        {" "}
                        {loader ?
                          <InlineLoader />
                          :
                          <>
                            {dashbordData ? <>{formatBalance(dashbordData?.totalRewardAmount)}</> : <>-</>}

                          </>
                        }
                      </h2>

                    </div>
                    {/* </Link> */}
                  </div>
                </Col>

              </Row>

            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
