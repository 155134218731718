import { createContext } from "react";
import swal from "sweetalert";



export const apiUrl = "http://138.197.86.118:3014/api/v1"
export const baseUrl = "http://138.197.86.118:3014/"
export const MyContext = createContext();
export const feedWalletAddress = "0x4887c63c2D322D4639b8128cb5C3C58849A732C9"
export const fundWalletAddress = "0xc42F48a8E1C2721FA25fdfDBb6A7d5e256A63694"

export const Unauthorized=()=>{swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear()
    window.location.href = "/";
    console.log('OK button clicked after error alert');
})}
export const  dateFormate = 'DD/MM/YYYY hh:mm A'
export const formatBalance = (rawBalance) => {
    // console.log((rawBalance/ 1000000000000000000))
    // const balance = (rawBalance / 1000000000000000000).toFixed(2)
    const balance = (rawBalance / 1000000).toFixed(2)
    return balance
  }
//   export const transactionurl =  "https://mumbai.polygonscan.com/tx/"
  export const transactionurl =  "https://polygonscan.com/tx/"

  export const thresholdTimeFram = [{value:240,label:"4 Hours"},{value:360,label:"6 Hours"},{value:720,label:"12 Hours"},{value:1440,label:"24 Hours"},{value:2880,label:"48 Hours"},{value:4320,label:"72 Hours"},{value:10080,label:"1 Week"},{value:20160,label:"2 Weeks"}]

