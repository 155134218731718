import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Tooltip,
  OverlayTrigger,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import { apiService } from "../../service/api.service";
import { validateService } from "../../service/validation.service";
import swal from "sweetalert";
import moment from "moment";
import Select from "react-select";


import { useNavigate } from "react-router-dom";
import { baseUrl,dateFormate , formatBalance,thresholdTimeFram } from "../../config/config";

export const PredictionManagement = () => {
  let history = useNavigate();
  function PredictionDetail(id) {
    history("/prediction-detail/" + id);
  }
  const getBalance = async () => {

    const response = await apiService.getBalance();
    if (response.status == 200) {
      //  setBalance(parseFloat(response.data.data).toFixed(4))
       localStorage.setItem("balance",parseFloat(response.data.data).toFixed(4))
     }
}
  const options = [
    { value: "", label: "All" },
    { value: "DAY", label: "1 Day" },
    { value: "WEEK", label: "1 Week" },
    { value: "MONTH", label: "1 Month" },

  ];


  const [loader, setLoader] = useState(false);
  const [addData, setaddData] = useState(false);
  const [endDate, setEndDate] = useState();
  const [startDate, setstartDate] = useState();
  const [startDateSend, setstartDateSend] = useState();
  const [type, setType] = useState("Upcoming");
 
  const [predictionFields, setpredictionFields] = useState({
    question: "",
    description: "",
    startDateTime: "",
    endDateTime: "",
    outcome: "",
    duration: "",
    threshold_time: "",
  });
  const [predictionFieldsErr, setpredictionFieldsErr] = useState({
    question: "",
    description: "",
    start_time: "",
    end_time: "",
    outcome: "",
    duration: "",
    threshold_time: "",
  });
  const handelCloseData = () => {
    setpredictionId()
    setaddData(false);
  }
  const [dataoptions, setdataoptios] = useState([]);
  const [totalItems, settotalItems] = useState();
  const [page, setPage] = useState(0);
  const [duration, setduration] = useState("");
  const [predictionId, setpredictionId] = useState();
  const [quesImage, setquesImage] = useState();
  const [quesImageshow, setquesImageshow] = useState();

  // function formatAMPM(date) {
  //   var hours = date.getHours();
  //   var minutes = date.getMinutes();
  //   var ampm = hours >= 12 ? 'pm' : 'am';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? '0'+minutes : minutes;
  //   var strTime = hours + ':' + minutes + ' ' + ampm;
  //   return strTime;
  // }
  const tooltip = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">Edit</p>
    </Tooltip>
  );
  const tooltipview = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">View</p>
    </Tooltip>
  );
  const tooltiptoggle = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">Change Status</p>
    </Tooltip>
  );
  const tooltipinfo = (
    <Tooltip id="tooltip">
     The bet time will appear, adjusted by substracting from the threshold time.
    </Tooltip>
  );

  useEffect(() => {
    get_prediction(0, type, duration);
  }, []);

  function getmintime() {
    let minTime = new Date().toISOString().slice(0, -8);
    var d = new Date(minTime);
    d.setHours(d.getHours() + 1);
    return minTime;
  }

  function handlePageChange(pageNumber) {
    if(page!=pageNumber-1){
      setPage(pageNumber - 1);
      get_prediction(pageNumber - 1, type, duration);

    }
    
  }
  function handleDurationChange(durations) {
    if (durations != duration) {
      setduration(durations);
      get_prediction(page, type, durations);
    }

  }
  async function get_prediction(page, type, duration) {
    setLoader(true);
    try {
      const response = await apiService.get_prediction(page, type, duration);
      if (response.status == 200) {
        console.log(response.data);
        setdataoptios(response.data.data.list);
        settotalItems(response.data.data.totalRecords);
        setLoader(false);
      }
    } catch (error) {
      console.log(error)
      setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  }
  function convertToUtc(dateTime) {
    let conversionDate = new Date(
      new Date(dateTime)
    ).toISOString()
      .slice(0, -5);
    let d2 = moment.utc(conversionDate).format(dateFormate)
    d2 = d2.toString()
    return d2
    //  return Math.floor(new Date(d2).getTime()/ 1000)
    console.log("====ttt====", Math.floor(new Date(d2).getTime() / 1000))
  }
  function OnchangeFromtime(timefrom) {
    // var dateTimeInput = document.getElementById('dateTime');

    //         // Close the date and time picker
    //         dateTimeInput.blur();
    setpredictionFields((prevObj) => {
      return {
        ...prevObj,
        startDateTime: timefrom.toLocaleString(),
      };
    });
    setstartDateSend(new Date(timefrom).toISOString() .slice(0, -5))
    let startdateutc = convertToUtc(timefrom)
    setstartDate(startdateutc)
    endDateDuration(predictionFields.duration, timefrom);
  }

  function endDateDuration(duration, timefrom) {
    if (duration == "DAY" && timefrom) {
      let fromdate = new Date(
        new Date(timefrom).getTime() + 1 * 86400000
      ).toISOString()
        .slice(0, -5);
        let dateFormat =  dateFormate
        setEndDate(moment.utc(fromdate).format(dateFormat))

      // setEndDate(fromdate)
      let fromdateLocal = moment(new Date(
        new Date(timefrom).getTime() + 1 * 86400000
      )).format(dateFormate);

      // let testDateUtc = moment.utc(fromdate)
    console.log("=======fromdateLocal===",fromdateLocal)
      // var localDate = testDateUtc.local();
      // var dateFormat = dateFormate;
      // console.log(localDate.format(dateFormat));
      // console.log("=======localDate===", localDate)
      // let startdateutc = convertToUtc(fromdate)
    
          //  console.log("=====>>>",moment.utc(fromdate).format(dateFormate))
          //  console.log("=====>>>",moment(new Date(startdateutc)).format(dateFormat))
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          endDateTime:fromdateLocal
        };
      });
    } else if (duration == "WEEK" && timefrom) {
      var fromdate = new Date(new Date(timefrom).getTime() + 7 * 86400000)
        .toISOString()
        .slice(0, -5);
        setEndDate(moment.utc(fromdate).format(dateFormate))
        let fromdateLocal = moment(new Date(
          new Date(timefrom).getTime()+ 7 * 86400000
        )).format(dateFormate)
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          endDateTime:  fromdateLocal,
        };
      });
    } else if (duration == "MONTH" && timefrom) {
      var fromdate = new Date(new Date(timefrom).getMonth() + 1)
        .toISOString()
        .slice(0, -5);

     
      var endDate = new Date(timefrom);
      endDate = new Date(endDate.setMonth(endDate.getMonth() + 1)).toISOString()
        .slice(0, -5);
        var endDateLocal = new Date(timefrom);
        endDateLocal =  moment(new Date(endDateLocal.setMonth(endDateLocal.getMonth() + 1))).format(dateFormate)
        // endDateLocal =    moment(new Date(new Date(endDateLocal).getMonth() + 1 )).format(dateFormate)
      // setEndDate(moment(endDate).format(dateFormate))
      // setEndDate(moment.utc(fromdate).format(dateFormate))
      setEndDate(moment.utc(endDate).format(dateFormate))
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          endDateTime:  endDateLocal,
        };
      });
    }
  }
  function OnchangeTotime(timefrom, label) {
    setpredictionFields((prevObj) => {
      return {
        ...prevObj,
        endDateTime: timefrom.toLocaleString(),
      };
    });
  }
  function toISOLocal(d) {
    var z = n => ('0' + n).slice(-2);
    var zz = n => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    console.log("==off===",off)
    var sign = off > 0 ? '-' : '+';
    off = Math.abs(off);
    console.log("==off===",off)
    return d.getFullYear() + '-'
      + z(d.getMonth() + 1) + '-' +
      z(d.getDate()) + 'T' +
      z(d.getHours()) + ':' +
      z(d.getMinutes()) + ':' +
      z(d.getSeconds()) + '.' +
      zz(d.getMilliseconds()) +
      sign + z(off / 60 | 0) + ':' + z(off % 60);
  }
  function resetFields() {
    setquesImageshow()
    setquesImage()
setstartDateSend()
    setstartDate()
    setEndDate()
    setpredictionFields({
      question: "",
      description: "",
      startDateTime: "",
      endDateTime: "",
      duration: "",
      outcome: "",
      threshold_time: "",
    })
    setpredictionFieldsErr({
      question: "",
      description: "",
      start_time: "",
      end_time: "",
      outcome: "",
      duration: "",
      threshold_time: "",
    })
  }
  const removeImage=()=>{
    if(predictionId && quesImage){
      setquesImage('')
    }else{
      setquesImage()
    }
 
    setquesImageshow()
  }
  function getDateFromUTC(dateString) {
    const date = dateString
      .split(" ")[0]
      .split("-")
      .map((number) => parseInt(number));
    const hours = dateString
      .split(" ")[1]
      .split(":")
      .map((num) => parseInt(num));
  
    const convertedDate = new Date(
      Date.UTC(date[2], date[1] - 1, date[0], hours[0], hours[1], hours[2])
    );
    return convertedDate;
  }
  async function editPrediction(id) {
    resetFields()
    setpredictionId(id)
    const response = await apiService.get_predictionDetail(id);
    if (response.status == 200) {
      console.log("=====>>",response.data.data)
      setpredictionFields(response.data.data)
      if (response.data.data.image && response.data.data.image != "undefined") {
        setquesImageshow(baseUrl + response.data.data.image)
      }
      console.log("response.data.data.startDateTime", moment.utc(new Date(response.data.data.startDateTime)));
      var fromdate = new Date(response.data.data.startDateTime).toISOString().slice(0, -5)
      let tmpDate = new Date(response.data.data.startDateTime).toISOString();
      let startdateutc = convertToUtc(new Date(response.data.data.startDateTime))
      setstartDate(moment.utc(new Date(response.data.data.startDateTime)))
       setstartDateSend(moment.utc(new Date(response.data.data.startDateTime)))
      // setstartDate(fromdate)
       setquesImage(response.data.data.image)
      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
     

      // var localISOTime = (new Date(tmpDate - tzoffset)).toISOString().slice(0, -1);

      // console.log("localISOTime>>>>",localISOTime)  // => '2015-01-26T06:40:36.181'


      var todate = new Date(response.data.data.endDateTime).toISOString().slice(0, -5)
      var fromdate = new Date(response.data.data.startDateTime).toISOString().slice(0, -5)
      const dateString = fromdate;
      const localDate1 = new Date(dateString + "Z");
      console.log(localDate1)


      let startDateTime =new Date(response.data.data.startDateTime);
      // startDateTime.setMinutes(startDateTime.getMinutes() - 
      // startDateTime.getTimezoneOffset());
      // let localStartDateTime = startDateTime.getFullYear() + "-" + 
      //        (startDateTime.getMonth() + 1).toString().padStart(2, '0') +
      //        "-" + startDateTime.getDate().toString().padStart(2, '0') + 
      //        "T" + startDateTime.getHours().toString().padStart(2, '0') + 
      //        ":" + startDateTime.getMinutes().toString().padStart(2, '0') 
      //        + ":" +
      //        startDateTime.getSeconds().toString().padStart(2, '0');
          

      // var utc = new Date(todate);
      // var offset = utc.getTimezoneOffset();
      // var local = new Date(utc.getTime() + offset * 60000);
      // let localdate = toISOLocal(new Date(fromdate))
       console.log("======localStartDateTime====>",startDateTime)
     let  localStartDateTime = new Date(startDateTime)
      // console.log(localdate.slice(0, -13))
      // localStartDateTime.setHours(localStartDateTime.getHours() + 1);
       localStartDateTime = moment(localStartDateTime).format("yyyy-MM-DDTHH:mm:ss").toString()
      // console.log(localdate.slice(0, -13))
       console.log(localStartDateTime)
      // let localdate = new Date(response.data.data.startDateTime)
    
      setstartDate(moment.utc(response.data.data.startDateTime).format(dateFormate))
    todate = moment(response.data.data.endDateTime).format(dateFormate)
    let utctodate = moment.utc(response.data.data.endDateTime).format(dateFormate)
    setEndDate(utctodate)
    // setstartDateSend(new Date(response.data.data.startDateTime).toISOString().slice(0, -5))
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          startDateTime: localStartDateTime,
        };
      });
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          endDateTime: todate,
        };
      });
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          outcome: response.data.data.outcome ? "true" : "false",
        };
      });
      setaddData(true)
    }

  }
  const onChangeImage = (e) => {
    const data = e.target.files[0]
    console.log("onchangeimage...", data)
    setquesImage(data)
    setquesImageshow(URL.createObjectURL(data))
  }
  function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rminutes == 0) {
       if(rhours>1){
        return rhours + " Hours ";
       }else{
        return rhours + " Hour ";
       }
      
    } else {
      if(rhours>1){
        return rhours + " Hours " + rminutes + " mins.";
       }else{
        return rhours + " Hour " + rminutes + " mins.";
       }
     
    }

  }
  async function toTimestamp(strDate){
    // console.log("======00000====",new Date.parse(strDate))
    const dt = moment(strDate).unix();
    return dt.toString();
  }
  async function addprediction() {
    let valid = validateService.predictionValidate(
      predictionFields,
      setpredictionFieldsErr
    );

    if (valid) {
      setLoader(true);
      console.log(predictionFieldsErr)
      try {

        // if ((predictionFields.outcome = "true")) {
        predictionFields.outcome = true;
        // } else {

        //   predictionFields.outcome = false;
        // }
       
        // console.log("====startDate=",new Date(startDate).toISOString().slice(0, -5))
       
        var response;
        if (predictionId) {
          console.log("====startDate=",startDateSend);


 
          response = await apiService.updateprediction(predictionFields, predictionId, quesImage,startDateSend);
        } else {
          console.log(startDate)
          response = await apiService.addprediction(predictionFields, quesImage, startDateSend);
        }

        console.log(response)
        if (response.status == 200) {
          get_prediction(page, type, duration)
          setLoader(false);
          handelCloseData();

          swal("Success", response?.data?.message, "success").then(() => {

          });
        }
      } catch (error) {
        console.log("=======>>>>>",error.response.data.message)
      
        if(error.response.data.message.match("Returned error: insufficient funds for gas")){
          swal("Error","Insufficient funds for gas * price.Please add funds to your wallet","error")
          handelCloseData()
        }
        setLoader(false);
        if (error?.response?.status == 401) {
          swal({ text: "Unauthorized", button: "OK" }).then(() => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
        }
      }
    }
  }
  function matchExact(r, str) {
    var match = str.match(r);
    return match && str === match[0];
 }
  function handleSelect(key) {
    if (type != key) {
      setType(key)
      setPage(0)
      get_prediction(0, key, duration);
    }


    // if (key === 1)
    //   this.setState({ heading: "Log in" })
    // else
    //   this.setState({ heading: "Sign up" })
  }
  function parseMinutes(x) {
    let hours = Math.floor(x / 60);
    let minutes = x % 60;

    if (hours > 1) {
      if (minutes > 0) {
        return hours + " Hours" + " " + minutes + " min"
      } else {

      } return hours + " Hours"

    } else {
      if (hours == 1) {
        if (minutes > 0) {
          return hours + " Hour" + " " + minutes + " min"
        } else {

        } return hours + " Hour"

      } else {
        return minutes + " min"
      }

    }

  }
  function addDatapop() {
    getBalance()
    resetFields()
    setaddData(true)
  }

  return (
    <>
      <div className="dashboard-main-area">

        {loader ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">

            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Predictions</h2>
                  </div>
                  <div className="heading-top-area-right">
                    <div className="export-csv-btn add-btn-area me-2">
                      <Button
                        type="button"
                        variant="unset"
                        onClick={() => addDatapop()}
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i> Add
                      </Button>
                    </div>
                    <div className="filter-top-area">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="mb-0">Filter:</Form.Label>
                        <Select options={options} placeholder="All" onChange={(e) => handleDurationChange(e.value)} />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <Tabs
                  defaultActiveKey="Upcoming"
                  id="uncontrolled-tab-example"
                  className="prediction-tab mb-3"
                  onSelect={handleSelect}

                >
                  <Tab eventKey="Upcoming" title="Upcoming Prediction" >
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            {/* <th>Image</th> */}
                            <th>Statement Title</th>
                            <th className="email-section">Prediction Start Date & Time(UTC)</th>
                            <th>Prediction End Date & Time(UTC)</th>
                            {/* <th>Outcome</th> */}
                            <th>Threshold <OverlayTrigger placement="top" overlay={tooltipinfo}>
                                                     <i class="fa fa-info-circle" aria-hidden="true" ></i>
                                                    </OverlayTrigger></th>
                                                
                                                    <th>Total Predictions</th>
                            <th>Amount Predicted</th>
                            <th>Action</th>
                            {/* <th>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {dataoptions && dataoptions?.length > 0 ? (
                            dataoptions.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{(index + 1)}</td>
                                  {/* <td>{data?.image ? <img src={apiBaseUrl + data?.image} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} </td> */}
                                  <td className="question-area">
                                    {/* <a href={"/transaction?contract="+data?.contract_address}> */}
                                    {data?.question ? (
                                      <>{data?.question.length > 30 ? data?.question.slice(0, 30) + "..." : data?.question}</>
                                    ) : (
                                      <>-</>
                                    )}
                                    {/* </a> */}
                                  </td>
                                  {/* <td>{data?.last_name ? <>{data?.last_name}{" "}{data?.last_name}</> : <>-</>}</td> */}
                                  <td className="email-section">
                                    {data?.startDateTime ? (
                                      <>{moment.utc(data?.startDateTime).format("DD-MM-YYYY hh:mm A")
                                      }</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data?.endDateTime ? (
                                      <>{moment.utc(data?.endDateTime).format("DD-MM-YYYY hh:mm A")
                                      }</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>

                                  {/* <td>
                                    {data?.outcome ? <>{"YES"}</> : <>{"NO"}</>}
                                  </td> */}
                                  <td>
                                    {data?.threshold_time ? (
                                      <>{parseMinutes(data.threshold_time)}</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data.predictionCount}
                                  </td>
                                  <td>
                                    {formatBalance(data.totalAmount)} USDC
                                  </td>
                                  <td>
                                    <div className="view-eye-area">
                                      <div className="d-flex align-items-center" onClick={() => editPrediction(data.contract_address)}>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltip}
                                        >
                                          <div className="edit-pencil">
                                            <i
                                              className="fa fa-pencil"
                                              style={{ cursor: "pointer" }}
                                            ></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div>
                                      <div className="d-flex align-items-center" >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipview}
                                        >
                                          <div className="edit-pencil">
                                            <i class="fa fa-eye" onClick={() => PredictionDetail(data.contract_address)} style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div>
                                    </div>

                                  </td>
                                  {/* <td>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={tooltiptoggle}
                                    >
                                      <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                      />
                                    </OverlayTrigger>
                                   

                                  </td> */}
                                  {/* <td><button onClick={()=>makeCall(data?.phone_number,data._id)} className="btn btn-success">
          Call
        </button></td> */}
                                  {/* <td><button className="btn btn-success" onClick={()=>callList(data._id)}>View Keynote</button>
          
        </td> */}
                                  {/* <td style={{ cursor: "pointer" }} onClick={() => view(data?.deactivate_reason)}>
                                {data?.deactivate_reason ?

                                  <span className="loads-view">{"View Reason"}</span>
                                  : <>-</>}


                              </td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                    

                      <> </>

                      {/* </div> */}
                    </div>
                    {dataoptions.length > 0 && totalItems > 10 ? (
                        <Pagination
                          activePage={page + 1}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItems}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                          prevPageText={"Prev"}
                          nextPageText={"Next"}
                        />
                      ) : null}
                  </Tab>
                  <Tab eventKey="In-Progress" title="In-Progress Prediction" >
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            {/* <th>Image</th> */}
                            <th>Statement Title</th>

                            <th className="email-section">Prediction Start Date & Time(UTC)</th>
                            <th>Prediction End Date & Time(UTC)</th>
                            {/* <th>Outcome</th> */}
                            <th>Threshold <OverlayTrigger placement="top" overlay={tooltipinfo}>
                                                     <i class="fa fa-info-circle" aria-hidden="true" ></i>
                                                    </OverlayTrigger></th>
                            <th>Total Predictions</th>
                            <th>Amount Predicted</th>
                            <th>Action</th>
                            {/* <th>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {dataoptions && dataoptions?.length > 0 ? (
                            dataoptions.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{(index + 1)}</td>
                                  {/* <td>{data?.image ? <img src={apiBaseUrl + data?.image} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} </td> */}
                                  <td>
                                  <a href={"/transaction?contract="+data?.contract_address}>
                                    {data?.question ? (
                                      <>
                                        {data?.question.length > 25 ? data?.question.slice(0, 25) + "..." : data?.question}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                    </a>
                                  </td>
                                  {/* <td>{data?.last_name ? <>{data?.last_name}{" "}{data?.last_name}</> : <>-</>}</td> */}
                                  <td className="email-section">
                                    {data?.startDateTime ? (
                                      <>{moment.utc
                                        (data?.startDateTime).format("DD-MM-YYYY hh:mm A")
                                      }</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data?.endDateTime ? (
                                      <>{moment.utc(data?.endDateTime).format("DD-MM-YYYY hh:mm A")
                                      }</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  {/* 
                                  <td>
                                    {data?.outcome ? <>{"YES"}</> : <>{"NO"}</>}
                                  </td> */}
                                  <td>
                                    {data?.threshold_time ? (
                                      <>{parseMinutes(data.threshold_time)}</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data.predictionCount}
                                  </td>
                                  <td>
                                    {formatBalance(data.totalAmount)} USDC
                                  </td>
                                  <td>
                                    <div className="view-eye-area">
                             
                                      <div className="d-flex align-items-center" onClick={() => PredictionDetail(data.contract_address)}>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipview}
                                        >
                                          <div className="edit-pencil">
                                            <i class="fa fa-eye" style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div>
                                    </div>

                                  </td>
                                  {/* <td>

                                    <Form.Check // prettier-ignore
                                      type="switch"
                                      id="custom-switch"
                                    />

                                  </td> */}
                                  {/* <td><button onClick={()=>makeCall(data?.phone_number,data._id)} className="btn btn-success">
          Call
        </button></td> */}
                                  {/* <td><button className="btn btn-success" onClick={()=>callList(data._id)}>View Keynote</button>
          
        </td> */}
                                  {/* <td style={{ cursor: "pointer" }} onClick={() => view(data?.deactivate_reason)}>
                                {data?.deactivate_reason ?

                                  <span className="loads-view">{"View Reason"}</span>
                                  : <>-</>}


                              </td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>


                      <> </>

                      {/* </div> */}
                    </div>

                    {dataoptions.length > 0 && totalItems > 10 ? (
                        <Pagination
                          activePage={page + 1}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItems}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                          prevPageText={"Prev"}
                          nextPageText={"Next"}
                        />
                      ) : null}
                  </Tab>
                  <Tab eventKey="Past" title="Past Prediction">
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            {/* <th>Image</th> */}
                            <th>Statement Title</th>

                            <th className="email-section">Prediction Start Date & Time(UTC)</th>
                            <th>Prediction End Date & Time(UTC)</th>
                            {/* <th>Outcome</th> */}
                            <th>Threshold  <OverlayTrigger placement="top" overlay={tooltipinfo}>
                                                     <i class="fa fa-info-circle" aria-hidden="true" ></i>
                                                    </OverlayTrigger></th>
                            <th>Total Predictions</th>
                            <th>Amount Predicted</th>
                            <th>Action</th>
                            {/* <th>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {dataoptions && dataoptions?.length > 0 ? (
                            dataoptions.map((data, index) => {

                              return (
                                <tr key={index}>
                                  <td>{(index + 1)}</td>
                                  {/* <td>{data?.image ? <img src={apiBaseUrl + data?.image} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} </td> */}
                                  <td>
                                    <a href={"/transaction?contract="+data?.contract_address}>
                                    {data?.question ? (
                                      <>{
                                        
                                        data?.question.length > 25 ? data?.question.slice(0, 25) + "..." : data?.question
                                        
                                        }</>
                                    ) : (
                                      <>-</>
                                    )}</a>
                                  </td>
                                  {/* <td>{data?.last_name ? <>{data?.last_name}{" "}{data?.last_name}</> : <>-</>}</td> */}
                                  <td className="email-section">
                                    {data?.startDateTime ? (<>{moment.utc(data?.startDateTime).format("DD-MM-YYYY hh:mm A")}</>)
                                      : (<>-</>)}
                                  </td>
                                  <td>
                                    {data?.endDateTime ? (<>{moment.utc(data?.endDateTime).format("DD-MM-YYYY hh:mm A")}</>) : (<>-</>)}
                                  </td>

                                  {/* <td>
                                    {data?.outcome ? <>{"YES"}</> : <>{"NO"}</>}
                                  </td> */}
                                  <td>
                                    {data?.threshold_time ? (
                                      <>{parseMinutes(data.threshold_time)}</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data.predictionCount}
                                    
                                  </td>
                                  <td>
                                  {formatBalance(data.totalAmount)} USDC
                                  </td>
                                  <td>
                                    <div className="view-eye-area">
                                      {/* <div className="d-flex align-items-center" onClick={() => editPrediction(data.contract_address)}>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltip}
                                        >
                                          <div className="edit-pencil">
                                            <i
                                              className="fa fa-pencil"
                                              style={{ cursor: "pointer" }}
                                            ></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div> */}
                                      <div className="d-flex align-items-center" onClick={() => PredictionDetail(data.contract_address)}>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipview}
                                        >
                                          <div className="edit-pencil">
                                            <i class="fa fa-eye" style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div>
                                    </div>

                                  </td>
                         
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      

                      <> </>

                      {/* </div> */}
                    </div>
                    {dataoptions.length > 0 && totalItems > 10 ? (
                        <Pagination
                          activePage={page + 1}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItems}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                          prevPageText={"Prev"}
                          nextPageText={"Next"}
                        />
                      ) : null}
                  </Tab>
                </Tabs>
              </Col>

            </Row>
          </div>
        </Container>
      </div>
      <Modal
        className="add-pediction-area"
        show={addData}
        onHide={handelCloseData}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            {predictionId ? "Update" : "Add"} Prediction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3">
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Image</Form.Label>
             
         
        
             
                <div className="common-image-area">
                {!quesImageshow &&
               <div className="upload-image-area">
                <input type="file" onChange={(e) => onChangeImage(e)} />
                <i class="fa fa-plus" aria-hidden="true"></i>    </div>
                }
           {quesImageshow &&
              <div className="upload-image-area-spacing">
                <div className="upload-image-area-right">
                  <img src={quesImageshow} alt="iamge" height={200} width={200} />
                  <i class="fa fa-trash" style={{cursor:"pointer"}} aria-hidden="true" onClick={removeImage}></i>
                </div>
              
                <div className="choose-image-area">
                  <button type="button" style={{cursor:"pointer"}} variant="unset" >Change Image</button>
                  <input type="file" onChange={(e) => onChangeImage(e)}/>
                </div>
                </div>}
                </div>

            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Statement Title <span>*</span></Form.Label>
              <input
                maxLength={100}
                className="form-control "
                type="textarea"
                value={predictionFields.question}
                onChange={(e) => {
                  setpredictionFields((prevObj) => {
                    return {
                      ...prevObj,
                      question: e.target.value,
                    };
                  });
                }}

              />
              <span className="text-danger">
                {predictionFieldsErr.question}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Statement description <span>*</span></Form.Label>
              <textarea
                maxLength={1500}
                className="form-control "
                type="textarea"
                value={predictionFields.description}
                onChange={(e) => {
                  setpredictionFields((prevObj) => {
                    return {
                      ...prevObj,
                      description: e.target.value,
                    };
                  });
                }}

              />
              <span className="text-danger">
                {predictionFieldsErr.description}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Prediction Duration <span>*</span></Form.Label>
              <div className="dropdown-icon-area">
                <select
                  className="form-control"
                  onChange={(e) => {
                    setpredictionFields((prevObj) => {
                      if(e.target.value!==predictionFields.duration){
                      
                      
                      return {
                        ...prevObj,
                        duration: e.target.value,
                        threshold_time:""
                      };
                    }else{
                      return {
                        ...prevObj,
                        duration: e.target.value,
                      };
                    }
                    });
                    endDateDuration(
                      e.target.value,
                      predictionFields.startDateTime
                    );
                  }}
                  value={predictionFields.duration}
                >
                  <option value="">Please Select</option>
                   <option value={"HOUR"}>One Hour</option> 
                  <option value={"DAY"}>One Day</option>
                  <option value={"WEEK"}>One Week</option>
                  <option value={"MONTH"}>One Month</option>
                </select>
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>

              <span className="text-danger">
                {predictionFieldsErr.duration}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Prediction Start Date & Time <span>*</span></Form.Label>
              <input
                className="form-control "
                id="dateTime"
                value={predictionFields.startDateTime}
                  min={getmintime()}
                  onKeyDown={(e) => e.preventDefault()}
                type="datetime-local"
                onChange={(e) => OnchangeFromtime(e.target.value)}
                autoClose={true}

              />
              <p className="utc-time"><span className="sub-head">Betting Start Date & Time(UTC):</span>{startDate}</p>
              <span className="text-danger">
                {predictionFieldsErr.start_time}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Prediction End Date & Time <span>*</span></Form.Label>
              {/* {endDate?moment(endDate).format(dateFormate):""} */}
              <div className="dropdown-icon-area">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  onChange={(e) => OnchangeTotime(e.target.value)}
                  value={predictionFields.endDateTime}
                />
                <i class="fa fa-calendar-o" aria-hidden="true"></i>
              </div>
              <p className="utc-time"><span className="sub-head">Prediction End Date & Time(UTC):{endDate}</span></p>
              <span className="text-danger">
                {predictionFieldsErr.end_time}
              </span>
            </Form.Group>
           
            <Form.Group className="mb-3">
              <Form.Label>Threshold Time <OverlayTrigger placement="top" overlay={tooltipinfo}>
                                                     <i class="fa fa-info-circle" aria-hidden="true" ></i>
                                                    </OverlayTrigger> <span>*</span></Form.Label>
              <div className="dropdown-icon-area">
                <select
                  className="form-control "
                  onChange={(e) => {
                    setpredictionFields((prevObj) => {
                      return {
                        ...prevObj,
                        threshold_time: e.target.value,
                      };
                    });
                  }}
                  value={predictionFields.threshold_time}
                >
                  <option value="">Please Select</option>
                 
                 <option value={2}>2 Min</option> 
                  
                  {thresholdTimeFram.map((threshold,i) => (
                    <>
                      {/* {predictionFields.duration=="DAY" && threshold.value<1440 &&
                      <option key={i} value={threshold.value} >
                           
                      {threshold.label}
                      </option>}
                      {predictionFields.duration=="WEEK" && threshold.value<10080 &&
                      <option key={i} value={threshold.value}>
                           
                      {threshold.label}
                      </option>
                      } */}

                      <option key={i} value={threshold.value}>
                           
                      {threshold.label}
                      </option>
                      </>
                    
                    ))}
                </select>
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>


              <span className="text-danger">
                {predictionFieldsErr.threshold_time}
              </span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => addprediction()}>Save</Button>
          <Button onClick={handelCloseData} className="close-btn-bottom">Close</Button>

        </Modal.Footer>
      </Modal>

    </>
  );
};
