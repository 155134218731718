import React, { useEffect, useState } from "react";
import { Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "../../service/api.service";
import { baseUrl,formatBalance } from "../../config/config";
import { ThreeDotSpinner } from "../loader";
import moment from "moment";
export const PredictionDetail = () => {
    const tooltip = (
        <Tooltip id="tooltip">
         The bet time will appear, adjusted by substracting from the threshold time.
        </Tooltip>
      );
    
    const props = useParams()
    const [loader, setLoader] = useState(false);
    const history = useNavigate()
    const [detailData, setdetailData] = useState();
    const [allCount, setallCount] = useState();
    const backbutton = () => {
        history("/predictions")

    }
    function timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        if (rminutes == 0) {
            if (rhours > 1) {
                return rhours + " Hours ";
            } else {
                return rhours + " Hour ";
            }

        } else {
            if (rhours > 1) {
                return rhours + " Hours " + rminutes + " mins.";
            } else {
                return rhours + " Hour " + rminutes + " mins.";
            }

        }

    }
    useEffect(() => {
        getDetail()
    }, []);
    const getDetail = async () => {
        setLoader(true)
        const response = await apiService.get_predictionDetail(props.id);
        if (response.status == 200) {
            setdetailData(response.data.data)
            setallCount(response.data)
            setLoader(false)
        }
    }
    return (
        <>
            <div className="dashboard-main-area">
                {loader ? <ThreeDotSpinner /> : ""}
                <section className="common-padding prediction-detail-area">
                    <Container fluid className="spacing-area-section">
                        <Row className="justify-content-center">
                            <Col md={12} lg={11}>
                                <div className="top-back-btn" onClick={() => history(-1)}>
                                    <button type="button" variant="unset"><i class="fa fa-chevron-left" aria-hidden="true"></i> Back</button>
                                </div>
                                <div className="heading-top-area">
                                    <div className="d-flex ">
                                        <h2 className="align-self-center m-0">Prediction Detail</h2>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center m-2">
                            <Col md={12} lg={11}>
                                <Row className="bg-main-area">
                                    <Col md={12} lg={5} xl={6}>
                                        <div className="prediction-detail-left">
                                            <div className="prediction-detail-image">
                                                {detailData?.image && detailData?.image != "undefined" ? <img src={baseUrl + detailData?.image} /> :
                                                    <img src={require("../../assets/images/dummy.png")} alt="img" />}
                                            </div>
                                            <div className="prediction-detail-left-content">
                                                <h5>{detailData?.question}</h5>
                                                <p>{detailData?.description}</p>
                                            </div>

                                        </div>
                                    </Col>
                                    <Col md={12} lg={7} xl={6}>
                                        <div className="prediction-detail-right">
                                            <div className="prediction-time-top-area">
                                                <img src={require("../../assets/images/bg-vector.png")} />
                                                <p>1 {detailData?.duration.toLowerCase()}</p>
                                            </div>
                                            <div className="prediction-detail-right-top-heading">
                                                <h6>Threshold Time
                                                     <OverlayTrigger placement="top" overlay={tooltip}>
                                                     <i class="fa fa-info-circle ms-1" aria-hidden="true" ></i>
                                                    </OverlayTrigger></h6>
                                                <p>{timeConvert(detailData?.threshold_time)}</p>
                                            </div>
                                            <p className="progress-total">True : <span>{formatBalance(allCount?.totalTrueAmount)} USDC </span>({allCount?.trueCount} bets)</p>
                                            <p className="progress-total">False : <span>{formatBalance(allCount?.totalFalseAmount)} USDC </span>({allCount?.falseCount} bets)</p>
                                            <p className="progress-total">Total : <span>{formatBalance(allCount?.totalAmount)} USDC </span>({allCount?.totalCount} bets)</p>
                                            <div className="betting-window">
                                                <h6>Prediction Timeframe  :</h6>
                                                <div className="betting-window-content">
                                                    <div className="start-end-date">
                                                        <div className="start-date">
                                                            <p>Start Date & Time<span>(utc)</span> </p>
                                                        </div>
                                                        <div className="end-date">
                                                            <p>End Date & Time<span>(utc)</span> </p>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div className="start-end-date">
                                                        <div className="start-date-info">
                                                            <p>  {moment.utc(detailData?.startDateTime).format("MMM DD, hh:mm A")
                                }</p>
                                                        </div>
                                                        <div className="end-date-info">
                                                            <p>  {moment.utc(detailData?.endDateTime).format("MMM DD, hh:mm A")
                                } </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>

                            </Col>

                        </Row>
                    </Container>
                </section>

            </div>

        </>
    );
};
